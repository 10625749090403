import React from 'react'
import { Helmet } from 'react-helmet'
import ISEOData from '../interfaces'
import { graphql, useStaticQuery } from 'gatsby'

type Props = {
	SEOData: ISEOData
}

const SEO = ({ SEOData }: Props) => {
	const {
		site: { siteMetadata },
	} = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					title
					description
					siteUrl
				}
			}
		}
	`)

	const url = `${siteMetadata.siteUrl}${
		SEOData.path !== '/' ? SEOData.path : ''
	}`

	return (
		<Helmet
			htmlAttributes={{
				lang: 'en',
			}}
		>
			<title>{SEOData.title}</title>
			<meta name='description' content={SEOData.description} />
			<meta property='og:type' content='website' />
			<meta property='og:title' content={SEOData.title} />
			<meta property='og:description' content={SEOData.description} />
			<meta property='og:url' content={url} />
			<meta name='twitter:card' content='summary' />
			<meta name='twitter:title' content={SEOData.title} />
			<meta name='twitter:description' content={SEOData.description} />
			<meta name='twitter:url' content={url} />
		</Helmet>
	)
}

export default SEO
