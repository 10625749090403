import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

type Props = {
	header: string
	text?: React.ReactNode
	buttonText: string
	to: string
}

const PageSuggestionBanner = ({ header, text, buttonText, to }: Props) => {
	return (
		<div className='my-8 lg:flex lg:items-center'>
			<div className='lg:mb-0 mb-4 lg:w-3/6'>
				<div className='font-bold text-pompadourViolet lg:text-2xl text-xl lg:pb-0 pb-1'>
					{header}
				</div>
				<div>{text}</div>
			</div>
			<div className='flex justify-center items-center lg:w-2/6'>
				<Link
					to={to}
					className="bg-mediumVioletRed bg-cover bg-[url('../images/cta-bg.png')] bg-[length:20%_60%] bg-left-bottom bg-no-repeat flex justify-between py-3 2xl:px-14 lg:px-10 px-8 text-white rounded-md font-bold w-full text-lg"
				>
					<div className='mr-5'>{buttonText}</div>
					<div className='flex items-center'>
						<StaticImage
							src={'../images/icon-arrow.png'}
							alt=''
							className='w-2 h-auto'
						/>
					</div>
				</Link>
			</div>
		</div>
	)
}

export default PageSuggestionBanner
