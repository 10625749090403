import * as React from 'react'
import Layout from '../templates/layout'
import Hero from '../components/Hero'

import '../scss/taking-exkivity.scss'

import DownloadAction from '../components/DownloadAction'
import PageSuggestionBanner from '../components/PageSuggestionBanner'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import { StaticImage } from 'gatsby-plugin-image'

import ImageGrapefruit from '../images/svg/grapefruit.svg'

type CheckedItemProps = {
	bold?: string
	text: string
}
const CheckedItems = ({ bold, text }: CheckedItemProps) => {
	return (
		<div className='grid grid-cols-10 mb-6'>
			<div className='md:col-span-1 col-span-2 md:pr-0 pr-2 flex justify-center md:items-center items-start'>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					className='h-8 w-8 text-goldenFizzGreen'
					fill='none'
					viewBox='0 0 24 24'
					stroke='currentColor'
					strokeWidth='2'
				>
					<path
						strokeLinecap='round'
						strokeLinejoin='round'
						d='M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z'
					/>
				</svg>
			</div>
			<div className='md:col-span-9 col-span-8'>
				{bold && <span className='text-mediumVioletRed font-bold'>{bold}</span>}{' '}
				{text}
			</div>
		</div>
	)
}

const divideItemsData = [
	{
		title: 'Set an alarm',
		text: 'to remind you to take your medication at the same time every day.',
	},
	{
		title: 'Mark your calendar',
		text: 'with an X after you have taken your medication each day.',
	},
	{
		title: 'Store your medication',
		text: 'in a specific place so you know where it is each day.',
	},
	{
		title: 'Ask someone you trust',
		text: 'to remind you to take your medication every day.',
	},
]

type Props = {
	data: any
	location: Location
}

const TakingExkivity = ({ data, location }: Props) => {
	return (
		<Layout>
			<SEO
				SEOData={{
					title: 'Taking EXKIVITY® (mobocertinib) | Oral Treatment',
					description:
						"Learn about once-daily oral EXKIVITY® (mobocertinib) to treat EGFR Exon20 insertion-positive non-small cell lung cancer (NSCLC) in people who've had platinum-based chemotherapy. See Patient Information, Safety, and Boxed Warning.",
					path: location.pathname,
				}}
			/>
			<Hero bgImage={data.heroImage} imageAlt=''>
				<h1 className='text-white'>Taking EXKIVITY</h1>
			</Hero>
			<div className='grid grid-cols-12'>
				<div className='col-start-2 col-span-10 mt-8'>
					<h2 className='text-pompadourViolet font-bold md:text-2xl text-xl mb-2'>
						How to take oral EXKIVITY
					</h2>
					<div className='text-mediumVioletRed font-bold'>
						Convenient, once daily, taken at home:
					</div>
				</div>
				<div className='col-start-2 col-span-10'>
					<div className='md:grid md:grid-cols-2 md:divide-x-6 md:divide-y-0 divide-y-6 pt-10 md:pb-20 pb-10'>
						<div className='md:pr-10 md:pb-0 pb-8'>
							<div className='text-mediumVioletRed font-bold md:text-2xl text-xl mb-2'>
								4 pills
							</div>
							<div>
								Take EXKIVITY{' '}
								<span className='text-mediumVioletRed font-bold'>
									exactly as your healthcare provider
								</span>
								&nbsp;tells you to take it. The recommended dosing for EXKIVITY is 4
								pills all taken at the same time once a day.
							</div>
						</div>
						<div className='md:pl-10 md:pt-0 pt-8'>
							<div className='text-mediumVioletRed font-bold md:text-2xl text-xl mb-2'>
								with or without food
							</div>
							<div>
								You can take EXKIVITY at any time as long as it&rsquo;s the{' '}
								<span className='text-mediumVioletRed font-bold'>
									same time every day
								</span>
								. You can take EXKIVITY with or without food. Just choose a time that is{' '}
								<span className='text-mediumVioletRed font-bold'>
									convenient for you
								</span>
								.
							</div>
						</div>
					</div>
				</div>
				<div className='col-start-2 col-span-10 mb-10'>
					<div className='grid grid-cols-10 md:mb-6 mb-3'>
						<div className='md:col-span-1 col-span-2 md:pr-0 pr-2 flex justify-center items-center'>
							<StaticImage src='../images/taking-exkivity/idea.png' alt='' />
						</div>
						<div className='md:col-span-9 col-span-8 flex items-end'>
							<div className='text-pompadourViolet font-bold md:md:text-2xl text-xl text-lg'>
								Things to remember
							</div>
						</div>
					</div>
					<CheckedItems
						bold='Swallow EXKIVITY capsules whole. '
						text='Do not open, chew, or dissolve the contents of the capsules'
					/>
					<CheckedItems
						bold='Do not change your dose or stop taking EXKIVITY '
						text='unless your healthcare provider tells you to'
					/>
					<CheckedItems text='Your healthcare provider may change your dose, temporarily stop, or permanently stop treatment with EXKIVITY if you develop certain side effects' />
					<CheckedItems text='If you miss a dose of EXKIVITY, and it has been more than 6 hours, skip the dose and take your next dose at your regularly scheduled time the next day' />
					<CheckedItems text='If you vomit after a dose of EXKIVITY, do not take an extra dose. Take your next dose at your regularly scheduled time the next day' />
					<CheckedItems text='Your healthcare provider will check the electrical activity of your heart with a test called an electrocardiogram (ECG) and do blood tests to check your electrolytes before starting and during treatment with EXKIVITY' />
				</div>
				<div className='col-start-2 col-span-10 lg:col-start-3 lg:col-span-7 bg-weakYellow md:flex md:justify-center md:items-center px-6 py-10'>
					<div className='flex justify-center items-center md:pr-5 pr-0 md:mb-0 mb-5'>
						<img
							src={ImageGrapefruit}
							alt=''
							className='w-auto h-[50px] md:h-[76.5px]'
						/>
					</div>
					<div className='text-center flex-grow lg:mr-16'>
						<span className='text-mediumVioletRed font-bold'>
							Avoid eating grapefruit or drinking grapefruit juice
						</span>{' '}
						<br className='hidden lg:block' />
						during treatment with EXKIVITY. Grapefruit may increase the amount of
						EXKIVITY in your blood.
					</div>
				</div>
			</div>
			<div className='bg-lightGrey mt-16 grid grid-cols-12'>
				<div className='col-start-2 col-span-10 py-12 z-0'>
					<div className='grid grid-cols-10 mb-12 flex md:items-center items-start'>
						<div className='md:col-span-1 col-span-2 pr-2 flex justify-center md:items-center items-start'>
							<StaticImage src='../images/taking-exkivity/calendar.png' alt='' />
						</div>
						<div className='md:col-span-9 col-span-8'>
							<div className='text-pompadourViolet md:text-2xl text-xl font-bold'>
								Tips for taking your medication daily
							</div>
							<div>
								Taking EXKIVITY every day is important. These tips may help you stay on
								track.
							</div>
						</div>
					</div>
					<div
						className={
							'md:flex md:divide-x-2 md:divide-y-0 divide-y-2 divide-white border-t-2 border-b-2 border-white'
						}
					>
						{divideItemsData.map((item, index) => (
							<div
								className={'px-5 py-7 w-full lg:w-1/4'}
								key={`divide-item-${index}`}
							>
								<div className='text-mediumVioletRed font-bold'>{item.title}</div>
								<div>{item.text}</div>
							</div>
						))}
					</div>
					<div className='grid grid-cols-10 py-12 mb-24 flex md:items-center items-start border-b-2 border-white'>
						<div className='md:col-span-1 col-span-2 pr-2 flex justify-center md:items-center items-start'>
							<StaticImage src='../images/taking-exkivity/plane.png' alt='' />
						</div>
						<div className='md:col-span-9 col-span-8'>
							Be sure to take your medication with you when you <strong>travel</strong>
							. If you are traveling by air, keep your medication in your carry-on bag.
							If you do leave it at home, call your healthcare provider right away.
							Your healthcare team may be able to get a new supply of EXKIVITY to you
							at your new location through a nearby pharmacy.
						</div>
					</div>
					<DownloadAction
						text='Download the Treatment Guide'
						images={{
							desktop:
								data.downloadTreatmentGuideDesktop.childImageSharp.gatsbyImageData,
							mobile:
								data.downloadTreatmentGuideMobile.childImageSharp.gatsbyImageData,
						}}
						fileURL='/brochures/Exkivity Patient Treatment Guide.pdf'
					/><span className="buttonFix">
					<PageSuggestionBanner
						header='How safe is oral EXKIVITY?'
						text={
							<>
								Side effects can occur with medications, some{' '}
								<br className='hidden lg:block' />
								more severe than others.
							</>
						}
						buttonText='Learn more about what to expect'
						to='/possible-side-effects'
					/></span>
				</div>
			</div>
		</Layout>
	)
}
export default TakingExkivity

export const query = graphql`
	{
		heroImage: file(
			relativePath: { eq: "taking-exkivity/hero-taking-exkivity.png" }
		) {
			...heroImage
		}

		downloadTreatmentGuideMobile: file(
			relativePath: { eq: "resources-treatment-guide-thumb.png" }
		) {
			...downloadActionImage
		}
		downloadTreatmentGuideDesktop: file(
			relativePath: { eq: "treatment-guide-thumbnail.png" }
		) {
			...downloadActionImage
		}
	}
`
