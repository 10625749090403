import React from 'react'

type Props = {
	text: string
	containerClass?: string
	fileURL: string
	images: any
}
import '../scss/download-action.scss'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'

const DownloadAction = ({ text, containerClass, fileURL, images }: Props) => {
	return (
		<a
			href={fileURL}
			target='_blank'
			className={`flex border-2 border-mediumVioletRed rounded-lg lg:mb-16 mb-8 download-action-button overflow-hidden ${
				containerClass === 'resources' ? `${containerClass}` : 'justify-around'
			} bg-white`}
			rel='noreferrer'
		>
			<div className={'flex image-wrapper'}>
				<GatsbyImage image={images.desktop} alt='' className='!hidden lg:!block' />
				<GatsbyImage
					image={images.mobile}
					alt=''
					className='!block lg:!hidden -ml-10 md:-ml-24'
				/>
			</div>
			<div
				className={`flex text-mediumVioletRed font-bold justify-center items-center px-5 ${
					containerClass === 'resources' ? 'lg:px-5 flex-1' : 'lg:px-10'
				}`}
			>
				<div
					className={`mr-2 md:mr-4 ${
						containerClass === 'resources' ? 'lg:mr-2' : 'lg:mr-5'
					}`}
				>
					{text}
				</div>
				<div className='flex'>
					<StaticImage
						src='../images/icon-download.png'
						alt=''
						className={`${
							containerClass === 'resources' ? 'w-5 md:w-5 lg:w-4' : 'w-4 lg:w-5'
						}`}
					/>
				</div>
			</div>
		</a>
	)
}

export default DownloadAction
